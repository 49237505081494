var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "peminjam",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Peminjam")]), _c('v-select', {
          attrs: {
            "options": _vm.karyawans,
            "label": "text",
            "reduce": function (option) {
              return option.value;
            }
          },
          model: {
            value: _vm.form.id_karyawan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_karyawan", $$v);
            },
            expression: "form.id_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lokasi",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Lokasi Peminjaman")]), _c('b-form-input', {
          model: {
            value: _vm.form.lokasi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lokasi", $$v);
            },
            expression: "form.lokasi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asset",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Aset")]), _c('v-select', {
          attrs: {
            "options": _vm.assets,
            "label": "text"
          },
          model: {
            value: _vm.selectedAsset,
            callback: function ($$v) {
              _vm.selectedAsset = $$v;
            },
            expression: "selectedAsset"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jumlah",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Jumlah Pinjam")]), _c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.form.jumlah,
            callback: function ($$v) {
              _vm.$set(_vm.form, "jumlah", $$v);
            },
            expression: "form.jumlah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.selectedAsset && _vm.stocks.length > 0 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Pilih tempat pengambilan Aset")]), _c('table', {
    staticClass: "table w-full responsive"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("NO")]), _c('th', [_vm._v("TEMPAT PENYIMPANAN")]), _c('th', [_vm._v("BLOK")]), _c('th', [_vm._v("STOK TERSEDIA")])]), _vm._l(_vm.stocks, function (stock, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [stock.stok > 0 ? _c('b-form-radio', {
      attrs: {
        "name": "stock",
        "value": stock
      },
      model: {
        value: _vm.selectedPenyimpanan,
        callback: function ($$v) {
          _vm.selectedPenyimpanan = $$v;
        },
        expression: "selectedPenyimpanan"
      }
    }) : _c('feather-icon', {
      staticClass: "text-danger",
      attrs: {
        "icon": "XIcon",
        "size": "24"
      }
    })], 1), _c('td', [_vm._v(_vm._s(++i))]), _c('td', [stock.gudang ? _c('span', [_vm._v(_vm._s(stock.gudang.nama_gudang))]) : _c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Tempat penyimpanan tidak ditemukan")])]), _c('td', [stock.blok ? _c('span', [_vm._v(_vm._s(stock.blok.blok))]) : _c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Blok tidak ditemukan")])]), _c('td', [_vm._v(" " + _vm._s(stock.stok) + " ")])]);
  })], 2)]) : _vm.selectedAsset && _vm.stocks.length < 1 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-danger p-1"
  }, [_vm._v(" Stok Aset belum ada ")])]) : _vm._e(), _c('b-col', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }