<template>
    <b-overlay :show="loading">
        <b-card>
            <validation-observer ref="form">
                <b-row>
                    <!-- Peminjam -->
                    <b-col sm="12" md="6" class="mb-2">
                        <validation-provider
                            #default="{ errors }"
                            name="peminjam"
                            rules="required"
                        >
                            <label for="">Pilih Peminjam</label>
                            <v-select :options="karyawans" label="text" :reduce="option => option.value" v-model="form.id_karyawan"></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <!-- / -->
                    <!-- Lokasi -->
                    <b-col sm="12" md="6" class="mb-2">
                        <validation-provider
                            #default="{ errors }"
                            name="lokasi"
                            rules="required"
                        >
                            <label for="">Lokasi Peminjaman</label>
                            <b-form-input v-model="form.lokasi"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <!-- / -->
                    <!-- Asset -->
                    <b-col sm="12" md="6" class="mb-2">
                        <validation-provider
                            #default="{ errors }"
                            name="asset"
                            rules="required"
                        >
                            <label for="">Pilih Aset</label>
                            <v-select :options="assets" label="text" v-model="selectedAsset"></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <!-- Jumlah Pinjam -->
                    <b-col sm="12" md="6" class="mb-2">
                        <validation-provider
                            #default="{ errors }"
                            name="jumlah"
                            :rules="`required`"
                        >
                            <label for="">Jumlah Pinjam</label>
                            <b-form-input type="number" v-model="form.jumlah"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    
                    <!-- Ketersediaan stock -->
                    <b-col v-if="selectedAsset && stocks.length > 0" cols="12">
                        <h5>Pilih tempat pengambilan Aset</h5>
                        <table class="table w-full responsive">
                            <tr>
                                <th>#</th>
                                <th>NO</th>
                                <th>TEMPAT PENYIMPANAN</th>
                                <th>BLOK</th>
                                <th>STOK TERSEDIA</th>
                            </tr>
                            <tr v-for="(stock, i) in stocks" :key="i">
                                <td>
                                    <b-form-radio v-if="stock.stok > 0" name="stock" v-model="selectedPenyimpanan" :value="stock"></b-form-radio>
                                    <feather-icon icon="XIcon" class="text-danger" size="24" v-else></feather-icon>
                                </td>
                                <td>{{ ++i }}</td>
                                <td>
                                    <span v-if="stock.gudang">{{stock.gudang.nama_gudang}}</span>
                                    <i class="text-danger" v-else>Tempat penyimpanan tidak ditemukan</i>
                                </td>
                                <td>
                                    <span v-if="stock.blok">{{stock.blok.blok}}</span>
                                    <i class="text-danger" v-else>Blok tidak ditemukan</i>
                                </td>
                                <td>
                                    {{stock.stok}}
                                </td>
                            </tr>
                        </table>
                    </b-col>
                    <b-col cols="12" v-else-if="selectedAsset && stocks.length < 1">
                        <div class="alert alert-danger p-1" >
                            Stok Aset belum ada
                        </div>
                    </b-col>
                    <!-- / -->
                    <b-col class="mt-4">
                        <div class="d-flex justify-content-end">
                            <b-button @click.prevent="submit" variant="primary">Simpan</b-button>
                        </div>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>
    </b-overlay>    
</template>
<script>
import {BButton, BOverlay, BRow, BCol, BFormInput, BCard, BFormRadio} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {required} from '@validations'
export default {
    watch: {
        selectedAsset(asset) {
            if(asset) {
                this.loading = true
                this.getPenyimpanan(asset.value)
                this.loading = false
            }
        },
        selectedPenyimpanan(penyimpanan) {
            if(penyimpanan) {
                this.currentMaxJumlah = penyimpanan.stok
            }
        }
    },
    components: {
        BButton, BOverlay, BRow, BCol, BFormInput, BCard, BFormRadio, vSelect, ValidationProvider, ValidationObserver
    },
    computed: {
        updateMode() {
            return this.$route.params.id ? true : false
        }
    },
    data: () => ({
        required,
        currentMaxJumlah: 0,
        loading: false,
        selectedAsset: null,
        selectedPenyimpanan: null,
        form: {
            id_penyimpanan: null,
            id_karyawan: null,
            jumlah: 0,
            lokasi: null
        },
        assets: [],
        stocks: [],
        karyawans: []
    }),
    methods: {
        submit() {
            this.$refs.form.validate().then(async success => {
                if(success) {
                    if(!this.selectedPenyimpanan) {
                        this.displayError({
                            message: 'Harap pilih lokasi pengambilan aset!'
                        })
                        return false
                    }

                    if(this.form.jumlah > this.selectedPenyimpanan.stok) {
                        this.displayError({
                            message: 'Jumlah pinjam tidak boleh melebihi stok aset yg dipilih!'
                        })
                        return false
                    }

                    this.form.id_penyimpanan = this.selectedPenyimpanan.id
                    const payload = this.form
                    if(this.$route.params.id) {
                        payload.id = this.$route.params.id
                    }

                    try {
                        this.loading = true
                        await this.$store.dispatch('asset/savePeminjaman', [payload])
                        this.displaySuccess({
                            message: 'Data peminjaman berhasil ditambahkan'
                        })
                        this.loading = false
                        setTimeout(() => {
                            this.$router.push(`/asset-peminjaman`)
                        }, 1000)
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }

                }
            })
        },
        async getPenyimpanan(asset_id) {
            const params = {asset_id}
            if(this.myGudang) {
                params.gudang_id = this.myGudang.id
            }
            this.loading = true
            const penyimpanans = await this.$store.dispatch('asset/getStock', params)
            this.stocks = penyimpanans
            this.loading = false
        },
        async getKaryawan() {
            const karyawans = await this.$store.dispatch('karyawan/getData')
            this.karyawans = karyawans.map(item => ({ value: item.id, text: item.nama_lengkap }))
        },
        async getAsset() {
            const params = {order: 'desc'}
            const assets = await this.$store.dispatch('asset/getBarang', params)
            if(assets.data) {
                this.assets = assets.data.map(item => ({value: item.id, text: item.nama, data: item}))
            }
        }
    },
    async created() {
        this.loading = true
        await this.getKaryawan()
        await this.getAsset()
        this.loading = false

        if(this.updateMode) {
            // get peminjaman
            const peminjaman = this.$store.getters['asset/searchPeminjaman'](this.$route.params.id)
            if(!peminjaman) {
                this.$router.push(`/asset-peminjaman`)
            }
            this.form.id_penyimpanan = peminjaman.id_penyimpanan
            this.form.id_karyawan = peminjaman.id_karyawan
            this.form.jumlah = peminjaman.jumlah
            this.form.lokasi = peminjaman.lokasi
        }
    }
}
</script>
